import React from 'react';
import { ContentSection } from '../content-section/content-section';

export const GamesSection = () => {
  return (
    <ContentSection
      id="games"
      title="Games"
      className="theme-gradient-green"
    >
      <p>
        Go play my games at <a href="https://superbarf.itch.io/" target="_blank">superbarf.itch.io</a>!
      </p>
    </ContentSection>
  );
};
