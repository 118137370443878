 import React from 'react';

export const ContentSectionWrapper = ({ className, children, id }) => {
  return (
    <section
      id={id}
      className={className}
    >
      {children}
    </section>
  )
}