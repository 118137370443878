import React from 'react';
import { Page } from '../../components/page/page';
import './homepage.scss';
import { AboutMeSection } from '../../components/sections/AboutMeSection';
import { WebsitesSection } from '../../components/sections/WebsitesSection';
import { AppsSection } from '../../components/sections/AppsSection';
import { GamesSection } from '../../components/sections/GamesSection';
import { ContactSection } from '../../components/sections/ContactSection';
import { ExtrasSection } from '../../components/sections/ExtrasSection';
import {WebsiteProjectBrowser} from '../../components/WebsiteProjectBrowser';
import {ContentSection} from '../../components/content-section/content-section';

const DEV_START_YEAR = 2014;

export function HomePage() {
  const yearsInDev = new Date().getFullYear() - DEV_START_YEAR;
  return(
    <Page>
      <WebsitesSection />
      <AppsSection />
      <GamesSection />
      <AboutMeSection yearsInDev={yearsInDev} />
      <ContactSection />
      <ExtrasSection />
    </Page>
  );
}