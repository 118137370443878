import React from 'react';
import { Page } from '../components/page/page';
import { publicResource } from '../components/utils/utils';
import { ProjectSection } from '../components/content-section/project-section/project-section';

export function AppsPage() {
  return(
    <Page>
      <ProjectSection id="ar-bizcard"
        title="Augmented Reality Business Card"
        className="theme-dark"
        featuredImage={publicResource('/images/ar-bizcard-screenshot.webp')}
        subheading="An augmented reality extension of my personal busniess card that delivers my brand with some extra zazz! This app was built in Unity, with all scripts written in C#. It also depends heavily on Vuforia's technology for image targeting and the AR camera that allows overlaying virtual content."
        tech={['Unity', 'C#', 'Vuforia']}
        download={{
          uri: 'http://downloads.jeremiahshore.net/ar-biz-card.apk',
          text: 'download Android APK'
        }}
        github="https://github.com/jeremiah-shore/ar-business-card"
        timeline="developed in the fall/winter of 2019"
      />
    </Page>
  );
}