import React from 'react';

import { ContentSection } from '../content-section/content-section';
import { ListOfLinks } from '../list-of-links/ListOfLinks';
import { Comment, Email, LocalPhone } from '@mui/icons-material';

const CONTACT_ITEMS= [
  {
    text: '(720) 577-5465',
    icon: <LocalPhone/>,
    path: 'tel:7205775465',
  },
  {
    text: 'contact@jeremiahshore.net',
    icon: <Email/>,
    path: 'mailto:contact@jeremiahshore.net',
  },
  {
    text: 'contact form',
    icon: <Comment/>,
    path: 'https://goo.gl/forms/W3L666ykHUj0TJpo2',
  },
];

export const ContactSection = () => {
  return (
    <ContentSection
      id="contact"
      className="theme-dark"
      title="Contact"
    >
      <ListOfLinks items={CONTACT_ITEMS} color={'limegreen'}/>
    </ContentSection>
  );
};