import React from 'react';
import {FeaturedImage} from './FeaturedImage';

export const ContentSectionContent = (props) => {
  return (
    <>
      <h2>{props.title}</h2>
      {props.subheading &&
      <p className="section-subheading">{props.subheading}</p>
      }
      <div className="section-wrapper">
        {props.featuredImage &&
          <FeaturedImage url={props.featuredImage} />
        }
        {props.children}
      </div>
    </>
  );
};
