import React from 'react';
import './card-gallery.scss';

export function CardGallery(props) {
  return(
    <div className="card-gallery"
      style={props.children && React.Children.count(props.children) === 1 ? 
        {display: "inherit"}
        : {}}
    >
      {props.children}
    </div>
  );
}