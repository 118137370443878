
import React from "react";
import './portfolio-line-item.scss';
import { AssignmentInd, Code, Create, Dns, DnsOutlined, GetApp, GitHub, Link, Stars, Timeline } from '@mui/icons-material';

const FONT_SIZE = "large";

export const PortfolioLineItem = ({icon, children}) => {
  const renderedIcon = (name) => {
    switch(name) {
      case 'timeline': return <Timeline fontSize={FONT_SIZE} />;
      case 'github': return <GitHub fontSize={FONT_SIZE} />;
      case 'download': return <GetApp fontSize={FONT_SIZE} />;
      case 'link': return <Link fontSize={FONT_SIZE} />;
      case 'dns': return <Dns fontSize={FONT_SIZE} />;
      case 'dns-outlined': return <DnsOutlined fontSize={FONT_SIZE} />;
      case 'code': return <Code fontSize={FONT_SIZE} />;
      case 'create': return <Create fontSize={FONT_SIZE}/>;
      case 'assignment': return <AssignmentInd fontSize={"large"}/>;
      default: return <Stars fontSize={FONT_SIZE} />
    }
  };
  return (
    <li className={"pli"} style={{ paddingBottom: '0.5em' }}>
      <div className={"pli--icon"} style={{ paddingRight: 10 }}>
        {renderedIcon(icon)}
      </div>
      <div className={"pli--children"}>
        <span>{children}</span>
      </div>
    </li>
  )
};