import React from 'react';
import './content-section.scss';
import {ContentSectionWrapper} from './ContentSectionWrapper';
import {ContentSectionContent} from './ContentSectionContent';

export const ContentSection = (props) => {
  return(
    <ContentSectionWrapper
      id={props.id}
      className={props.className}
    >
      <ContentSectionContent
        title={props.title}
        subheading={props.subheading}
        featuredImage={props.featuredImage}
      >
        {props.children}
      </ContentSectionContent>
    </ContentSectionWrapper>
  );
}
