import React from 'react';
import { CardGallery } from '../card-gallery/card-gallery';
import { Card } from '../card-gallery/card/card';
import { publicResource } from '../utils/utils';
import { ContentSection } from '../content-section/content-section';

export const WebsitesSection = () => {
  return (
    <ContentSection
      id="websites"
      title="Websites"
      className="theme-gradient-blue"
      subheading="A collection of web apps and sites that I've built, cleaned up, maintained, or contributed to."
    >
      <CardGallery>
        <Card
          label="Colorado Voice Studios"
          url="/websites#colorado-voice-studios"
          image={publicResource('/images/miss-emily-screenshot.webp')}
        />
        <Card
          label="Creatively Cannabis"
          url="/websites#creatively-cannabis"
          image={publicResource('/images/creatively-cannabis-screenshot.webp')}
        />
        <Card
          label="NU Food"
          url="/websites#nu-food"
          image={publicResource('/images/nuvegfood-screenshot.webp')}
        />
        <Card
          label="Strictly USA"
          url="/websites#strictly-usa"
          image={publicResource('/images/strictlyusa-screenshot.webp')}
        />
        <Card
          label="Shin's Piano Lessons"
          url="/websites#shins-piano"
          image={publicResource('/images/shinspiano-screenshot.webp')}
        />
        <Card
          label="Bandwave Systems"
          url="/websites#bandwave-systems"
          image={publicResource('/images/bandwave-screenshot.webp')}
        />
        <Card
          label="Shorefire Technologies"
          url="/websites#shorefire-tech"
          image={publicResource('/images/shorefiretech-screenshot.webp')}
        />
        <Card
          label="Frisco Wine Merchant"
          url="/websites#frisco-wine"
          image={publicResource('/images/frisco-wine-screenshot.webp')}
        />
        <Card
          label="Marageti"
          url="/websites#marageti"
          image={publicResource('/images/marageti-search-page.png')}
        />
        <Card
          label="Zicasso"
          url="/websites#zicasso"
          image={publicResource('/images/zicasso-homepage.png')}
        />
        <Card
          label="Pax8 Platform"
          url="/websites#pax8"
          image={publicResource('/images/pax8-platform-about.png')}
        />
        <Card
          label="Clover Merchant Dashboard"
          url="/websites#clover"
          image={publicResource('/images/clover-merchant-dashboard.png')}
        />
        <Card
          label={"CO Alt Medicine"}
          url={"/websites#coaltmeds"}
          image={publicResource('/images/coaltmeds.png')}
        />
        <Card
          label={"Nicole Ashton Art"}
          url={"/websites#nicole-ashton"}
          image={publicResource('/images/naa2.png')}
        />
      </CardGallery>
    </ContentSection>
  );
};
