import { Box } from '@mui/material';
import React from 'react';
import { PortfolioLineItem } from '../portfolio-line-item/portfolio-line-item';

export const ProductFeatureDetails = (props) => {
  return (
    <Box sx={{
      marginTop: {
        xs: 3,
        sm: 0,
      }
    }}>
      <ul style={{
        margin: 0,
        padding: '0 0 0 2rem',
        listStyleType: 'none',
      }}>
        {props.timeline &&
          <PortfolioLineItem icon={"timeline"}>
            {props.timeline}
          </PortfolioLineItem>
        }
        {props.github &&
          <PortfolioLineItem icon={"github"}>
            <a href={props.github}>view source on GitHub</a>
          </PortfolioLineItem>
        }
        {props.download &&
          <PortfolioLineItem icon={"download"}>
            <a href={props.download.uri}>{props.download.text}</a>
          </PortfolioLineItem>
        }
        {props.links && props.links.map((link, index) => (
          <PortfolioLineItem icon={"link"} key={index}>
            {link}
          </PortfolioLineItem>
        ))}
        {props.hosting &&
          <PortfolioLineItem icon={props.hosting.isActive ? 'dns' : 'dns-outlined'}>
            {`${props.hosting.isActive ? '' : 'originally '} hosted with ${props.hosting.description}`}
          </PortfolioLineItem>
        }
        {props.tech &&
          <PortfolioLineItem icon={"code"}>
            {props.tech.join(', ')}
          </PortfolioLineItem>
        }
        {props.copyWriting &&
          <PortfolioLineItem icon={"create"}>
            <p>work on this project included copy writing and content editing</p>
          </PortfolioLineItem>
        }
        {props.asEmployee &&
          <PortfolioLineItem icon={"assignment"}>
            I worked on this site as an employee
          </PortfolioLineItem>
        }
      </ul>
    </Box>
  );
};
