import React from 'react';
import { ContentSection } from '../content-section/content-section';
import { publicResource } from '../utils/utils';

export const AboutMeSection = ({ yearsInDev }) => {
  return (
    <ContentSection
      id="bio"
      title="About Me"
      className="theme-light"
      subheading={"Hello, I'm Jeremiah! I'm a software engineer based in the US."}
    >
      <p>I fell in love with computers when I took my first programming class in high school. I went to college to study computer networking and information technology, and I have an AAS degree in each field. I worked in IT for 8 years before realizing I both wanted to and was capable of doing software development work full-time.</p>
      <p>I completed a self-taught transition into engineering in early 2019. I've worked on a variety of freelance and professional projects; before, during, and since that time. My first full-time position in the field was when I joined Zicasso—one of my fondest work experiences!—as a back-end focused software engineer. Since then I've diversified my skill sets and covered a lot of ground at a few different types of companies. In summary, I've been working professionally on websites, apps, and games for {yearsInDev}+ years!</p>
      <p> My specialization is web-application development, which most people call "full-stack web developer" or "full-stack software engineer". I'm currently looking for my next role, somewhere near the senior level. Most of my recent work has been focused on front-end applications built in React. I'm passionate about building great user experiences. My entrepreneurial spirit runs deep, and my dream is to build an idea of my own into a thriving company. Most of all, I want to build things that create value and meaning in the world; products that provide delightful and helpful experiences!</p>
      <p>When I'm not dreaming up new projects or hacking them together, I'm usually studying, reading, working on <a href="http://soundcloud.com/user-620867192" target="_blank" rel="noreferrer">my music</a>, playing games, or spending time with friends and family. I currently live in Oscoda, MI with my lovely wife Tracy, our sweet cat Lady Pearl, our adorable dog Nugget, and our wonderful daughter ✨ Scarlett ✨.</p>
      <p>If there's anything else you want to know that I didn't cover here, please <a href="#contact">contact me</a>.</p>
      <p>Cheers! 👋</p>
      <div id="personal-pics">
        <img src={publicResource("/images/guitar.webp")} alt="me playing guitar" />
        <img src={publicResource("/images/hike.webp")} alt="on a hike in White Ranch Park in Jefferson County, CO " />
        <img src={publicResource("/images/pearl.webp")} alt="this crazy goofball is my cat Lady Pearl" />
        <img src={publicResource("/images/moose.webp")} alt="hanging out with a bronze moose in Winter Park, CO" />
        <img src={publicResource("/images/mountain.webp")} alt="posing in front of a ridge somewhere along Clear Creek, CO" />
      </div>
    </ContentSection>
  );
};
