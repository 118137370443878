import React from 'react';
import { BackToTop } from '../back-to-top/back-to-top';
import { SiteFooter } from '../site-footer/site-footer';
import { SiteHeader } from '../site-header/site-header';
import './page.scss';

export function Page(props) {
  return(
    <>
      <SiteHeader />
      <div id="content-wrapper">
        {props.children}
      </div>
      <SiteFooter />
      <BackToTop />
    </>
  );
}
