import React from 'react';
import { ProjectSection } from '../components/content-section/project-section/project-section';
import { Page } from '../components/page/page';

const THEME_ORDER = ['gradient-blue', 'dark', 'light', 'gradient-green', 'dark', 'light'];
const getTheme = (index) => `theme-${THEME_ORDER[(index) % THEME_ORDER.length]}`;

export const WesbitesPage = ({ items }) => {
  return (
    <Page>
      { items.map(({ id, title, featuredImage, description, tech, timeline, isActive, hosting, hasCopyWriting, workedAsEmployee, links }, index) => (
        <ProjectSection
          key={id}
          id={id}
          title={title}
          className={getTheme(index)}
          featuredImage={featuredImage}
          description={description}
          tech={tech}
          timeline={timeline}
          isInactive={!isActive}
          hosting={hosting}
          copyWriting={hasCopyWriting}
          asEmployee={workedAsEmployee}
          links={links}
        />
      ))}
    </Page>
  );
}