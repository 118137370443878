import React, { useEffect, useState } from 'react';
import './back-to-top.scss';
import { ArrowUpward } from '@mui/icons-material';

export function BackToTop() {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => (window.scrollY > 50) ? setIsVisible(true) : setIsVisible(false));
  }, []);

  return (
    <div 
      id="back-to-top"
      onClick={() =>  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
      className={isVisible ? 'visible' : 'invisible'}
    >
      <ArrowUpward style={{ fontSize: 50, padding: 0 }} />
    </div>
  );

}