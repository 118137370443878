import { publicResource } from '../utils/utils';

export const WEBSITES = [
  {
    id: 'colorado-voice-studios',
    title: 'Colorado Voice Studios',
    featuredImage: publicResource('/images/miss-emily-screenshot.webp'),
    description: 'An informational business website for a vocal instructor based out of Loveland, CO. Includes calls-to-action for booking vocal lessons and other complementary services. I took over maintenance of an existing WordPress site and cleaned it up with a new child theme, custom-styled content, and more reliable form controls.',
    tech: ['WordPress', 'HTML', 'CSS', 'PHP', 'JavaScript'],
    timeline: '2016 - 2020',
    isActive: false,
    hosting: {
      isActive: false,
      description: 'HostGator and Namecheap',
    },
    hasCopyWriting: true,
  },
  {
    id: 'creatively-cannabis',
    title: 'Creatively Cannabis',
    featuredImage: publicResource('/images/creatively-cannabis-screenshot.webp'),
    description: 'An informational business website for a "paint and smoke" service based in Denver, CO. Includes calls-to-action for booking painting lessons in a group setting with other cannabis enthusiasts. This site was designed, built from scratch, and released by me.',
    tech: ['Bootstrap', 'HTML', 'CSS', 'JavaScript'],
    timeline: '2019 - 2020, newer content may differ',
    links: [<a href="http://creativelycannabis.com" target="_blank" rel="noopener">creativelycannabis.com</a>],
    hosting: {
      isActive: true,
      description: 'Bluehost',
    },
    hasCopyWriting: true,
  },
  {
    id: 'bandwave-systems',
    title: 'Bandwave Systems',
    featuredImage: publicResource('/images/bandwave-screenshot.webp'),
    description: 'An informational business website for an managed IT service provider specializing in multi-site broadband connectivity. Includes calls to action for quote requests and partner registration. The 2015 version of this site was built in cooperation with an old colleague who was working as a contractor on the project. This version of the site was later replaced in 2017 with a redesign that I also contributed to. Most of my contributions to the 2017 version were focused on functionality and content, but I also helped fine tune some design aspects.',
    tech: ['WordPress', 'HTML', 'CSS','PHP'],
    timeline: '2015 - 2018, newer content may differ',
    links: [
      <a href="https://web.archive.org/web/20160114042552/http://www.bandwavesystems.com/" target="_blank" rel="noopener">archived 2015 version, may not work</a>,
      <p><a href="https://bandwavesystems.com" target="_blank" rel="noopener">bandwavesystems.com</a> (redesigned 2017 version)</p>
    ],
    workedAsEmployee: true,
    hosting: {
      isActive: false,
      description: 'Dreamhost',
    },
    hasCopyWriting: true,
  },
  {
    id: 'nu-food',
    title: 'NU Food',
    featuredImage: publicResource('/images/nuvegfood-screenshot.webp'),
    description: 'An informational and e-commerce website for a plant-based snack food company operating out of Denver, CO. Includes calls-to-action to learn more about products, find retail locations where products can be purchased, or to order online. Work on this site focused on making it more mobile-friendly, as well as improving various aspects of the user experience.',
    tech: ['WIX', 'HTML', 'CSS'],
    timeline: '2018 - 2019, newer content may differ',
    links: [<a href="http://nuvegfood.com" target="_blank" rel="noopener">nuvegfood.com</a>],
    hosting: {
      isActive: true,
      description: 'WIX',
    },
  },
  {
    id: 'strictly-usa',
    title: 'Strictly USA',
    featuredImage: publicResource('/images/strictlyusa-screenshot.webp'),
    description: 'An e-commerce website exclusively for USA-manufactured products. Work on this site was focused on a mobile-friendly redesign as well as some basic improvements to visual design and user experience. All improvements to the side were accomplished on a notably limited budget.',
    tech: ['3dCart', 'HTML', 'CSS', 'JavaScript'],
    timeline: '2018 - 2019, newer content may differ',
    links: [<a href="http://strictlyusa.com" target="_blank" rel="noopener">strictlyusa.com</a>],
    hosting: {
      isActive: true,
      description: '3dCart',
    },
  },
  {
    id: 'shins-piano',
    title: 'Shin\'s Piano Lessons',
    featuredImage: publicResource('/images/shinspiano-screenshot.webp'),
    description: 'An informational business website for a piano instructor. Includes call-to-action to register for piano lessons. I originally launched this as a WordPress website in early 2014, and it was one of the first websites that I worked on professionally. I later redesigned the site into a static single page design using Bootstrap.',
    tech: ['Bootstrap', 'HTML', 'CSS', 'JavaScript', 'jQuery'],
    timeline: '2014 - 2019',
    links: [<p><a href="http://shinspiano.jeremiahshore.net" target="_blank">shinspiano.jeremiahshore.net</a> (archive version)</p>],
    hosting: {
      isActive: true,
      description: 'Amazon Web Services',
    },
    hasCopyWriting: true,
  },
  {
    id: 'shorefire-tech',
    title: 'Shorefire Technologies',
    featuredImage: publicResource('/images/shorefiretech-screenshot.webp'),
    description: 'An informational website for a small-scale computer repair and IT services business I founded. The business was focused on serving residential and SMB customers in the area of NJ where I lived at the time. The site included calls-to-action to learn more about the available services and to contact me for a consultation. The website  was active for a limited time, as I ended up working for Bandwave Systems shortly after launching the concept.',
    tech: ['WordPress', 'HTML', 'CSS', 'PHP'],
    timeline: '2014',
    isActive: false,
    hosting: {
      isActive: false,
      description: 'Bluehost',
    },
    hasCopyWriting: true,
  },
  {
    id: 'frisco-wine',
    title: 'Frisco Wine Merchant',
    featuredImage: publicResource('/images/frisco-wine-screenshot.webp'),
    description: 'An informational website for a wine-focused event and consultation business. Calls to action included learning more about the business and the services available. It focused not only on in-store or in-home wine tasting events, but also upscale services like wine cellar management or long-term procurement of imported wines. This site was designed, built from scratch, and released by me.',
    tech: ['WordPress', 'Divi', 'HTML', 'CSS'],
    timeline: '2017 - 2018',
    isActive: false,
    hosting: {
      isActive: false,
      description: 'Bluehost',
    },
    hasCopyWriting: true,
  },
  {
    id: 'jsnet',
    title: 'Jeremiah\'s Portfolio Website',
    featuredImage: publicResource('/images/jsnet-screenshot.png'),
    description: 'I built THIS website to showcase my work. I\'ve been maintaining this site in one form or another since 2013. I originally launched it as a WordPress site with heavily customized theming. I later rebuilt it as a static site in 2017, and then again in React in 2020. Working at Zicasso, I had some minor exposure to React. Zicasso, being a travel company, was hit particularly hard by COVID-19. So, while on furlough, I decided to get more familiar with React by redesigning my site using it.',
    tech: ['React', 'JavaScript', 'HTML', 'CSS'],
    timeline: '2013 - present',
    links: [<a href={"/"}>jeremiahshore.net</a>],
    hosting: {
      isActive: true,
      description: 'Amazon Web Services',
    },
    hasCopyWriting: true,
  },
  {
    id: 'music-jsnet',
    title: 'Jeremiah\'s Music Website',
    featuredImage: publicResource('/images/music-jsnet-screenshot.png'),
    description: 'A website for my music that connects other forms of media together. In its current version, there\'s honestly not that much content. These days—late 2021 as of writing—I don\'t focus on my music as much as I used to. I\'ll keep this site going for now, and maybe I\'ll add more to it later. My biggest point of pride is designing the logo from scratch, all the way from hand-drawn concepts to a polished standalone SVG graphic. I\'ve even made decals of it using a vinyl cutter.',
    tech: ['React', 'JavaScript', 'HTML', 'CSS'],
    timeline: '2020 - present',
    links: [<a href="http://music.jeremiahshore.net" target="_blank">music.jeremiahshore.net</a>],
    hosting: {
      isActive: true,
      description: 'Amazon Web Services',
    },
    hasCopyWriting: true,
  },
  {
    id: 'marageti',
    title: 'Marageti',
    featuredImage: publicResource('/images/marageti-search-page.png'),
    description: 'A website for browsing fixed-itinerary travel packages. Marageti is a sub-brand for Zicasso and, essentially, a standalone product. For the front-end of this site, I helped with maintnence and minor bug fixes. For the back-end, I helped integrate a new tour provider and the upstream API functionality needed to show promotional offers.',
    tech: ['Angular', 'HTML', 'CSS', 'Java', 'SparkJava'],
    timeline: '2019 - 2020',
    links: [<a href={"https://marageti.com"} target={"_blank"} rel={"noopener"}>marageti.com</a> ],
    hosting: {
      isActive: true,
      description: 'Amazon Web Services',
    },
    workedAsEmployee: true,
  },
  {
    id: 'zicasso',
    title: 'Zicasso',
    featuredImage: publicResource('/images/zicasso-homepage.png'),
    description: <>A comprehensive luxury travel website. Primary calls-to-action include learning what the business' value proposition is, browsing content related to countries or areas of interest, and requesting a customized itinerary. In my time at Zicasso I helped work on a number of features on the site, as well as the underlying infrastructure. This included a high-stakes transition to a React-based version of the site that replaced an older version authored in Drupal. Please see <a href="/documents/jeremiah-shore-resume.pdf">my resume</a> for more info.</>,
    tech: ['React', 'TypeScript', 'Gatsby', 'HTML', 'CSS', 'JavaScript'],
    timeline: '2019 - 2021',
    links: [<a href={"http://zicasso.com"} target={"_blank"} rel={"noopener"}>zicasso.com</a>],
    hosting: {
      isActive: true,
      description: 'Amazon Web Services',
    },
    workedAsEmployee: true,
  },
  {
    id: 'pax8',
    title: 'Pax8 Platform',
    featuredImage: publicResource('/images/pax8-platform-about.png'),
    description: 'Pax8 provides an industry-leading marketplace and platform for procuring and managing cloud software. In my time at Pax8, I specifically worked on the product called the Pax8 Platform. On the back-end, the platform is primarily a Spring-based monolith application. On the front-end it\'s a Vue 2 monolith. I spent most of my time on the SWAT team, performing Agile software development with a Kanban methodology, primarily on bugfixing. Notably, I used this time to dramatically improve my front-end skills.',
    tech: ['Spring', 'Java', 'Groovy', 'Vue2', 'JavaScript', 'HTML', 'CSS'],
    timeline: '2021',
    links: [<a href="https://www.pax8.com/en-us/platform/" target={"_blank"} rel={"noopener"}>Pax8 Platform marketing site</a>],
    hosting: {
      isActive: true,
      description: 'Amazon Web Services',
    },
    workedAsEmployee: true,
  },
  {
    id: 'clover',
    title: 'Clover Merchant Dashboard',
    featuredImage: publicResource('/images/clover-merchant-dashboard.png'),
    description: 'This merchant dashboard is a web application where Clover customers—primarily small business owners or designated admins—can manage their business using a wealth of tools and apps. This dashboard shows information about the business, including a profile, employees, revenue, reporting, and so on. The team I worked on focused on Early Merchant Experience (EME), which is primarily onboarding and first-time use (FTU) centered content. The EME generally covers content merchant users interact with in the first 90 days of using the platform. In other words, it\'s a mission critical introduction to our merchant dashbaord platform. Most of my time at Clover was spent on the front-end of our monolith, working on maintenance, bug fixes, and feature additions.',
    tech: ['React', 'TypeScript', 'HTML', 'CSS', 'Jest', 'Cypress'],
    timeline: '2021 - 2022',
    hosting: {
      isActive: true,
      description: 'Google Cloud Platform',
    },
    workedAsEmployee: true,
  },
  {
    id: 'coaltmeds',
    title: 'Colorado Alternative Medicine',
    featuredImage: publicResource('/images/coaltmeds.png'),
    description: 'An informational business website for an Alternative Medicine company based out of Lakewood, CO. This version of the site was built very quickly as a redesign of an existing WIX website, with a focus on mobile-friendly design. It was built in a remarkably fast period, and within a limited budget. This is one of the best turnarounds I\'ve had on a freelance website project. When I obtained full-time employment with Pax8 in 2021, the owner opted to transfer maintenance to a company that could work on the site with higher availability; hence, the site at coaltmeds.com is no longer the version I created.',
    tech: ['React', 'HTML', 'CSS', 'JavaScript', 'Material-UI'],
    timeline: '2020',
    links: [<a href="http://coaltmeds.jeremiahshore.net.s3-website-us-west-2.amazonaws.com/" target={"_blank"} rel={"noopener"}>self-hosted archive version</a>],
    hosting: {
      isActive: true,
      description: 'Amazon Web Services',
    },
  },
  {
    id: 'nicole-ashton',
    title: 'Nicole Ashton Art',
    featuredImage: publicResource('/images/naa2.png'),
    description: 'A portfolio site for artist, sculptor, and family friend Nicole Ashton.',
    tech: ['React', 'HTML', 'CSS', 'JavaScript', 'Material-UI'],
    timeline: '2021 - present',
    links: [],
    hosting: {
      isActive: false,
      description: 'Amazon Web Services',
    },
  },
]





  


  


  


  


  


  


  


  


  


  


  


  


  


  


  
