import React from 'react';
import { CardGallery } from '../card-gallery/card-gallery';
import { Card } from '../card-gallery/card/card';
import { publicResource } from '../utils/utils';
import { ContentSection } from '../content-section/content-section';

export const AppsSection = () => {
  return (
    <ContentSection
      id="apps"
      title="Apps"
      className="theme-dark"
      subheading="A collection various apps and prototypes."
    >
      <CardGallery>
        <Card
          label="AR Business Card"
          image={publicResource('/images/ar-bizcard-screenshot.webp')}
          url="/apps#ar-bizcard"
          internal
        />
      </CardGallery>
    </ContentSection>
  );
};
