import { Menu } from '@mui/icons-material';
import React, { useState } from 'react';
import logo from './logo.png';
import './site-header.scss';

export function SiteHeader() {
  const [isVisible, setIsVisible] = useState(window.innerWidth >= 600);
  const status = isVisible ? '' : 'collapsed';

  return (
    <header>
      <nav id="home">
        <ul>
          <li id="personal-brand"><a href="/#home">
            <div id="personal-brand-wrapper">
              <h1 id="personal-name">Jeremiah Shore</h1>
              <span id="personal-title">Senior Software Engineer</span>
            </div>
          </a></li>
          <li id="logo"><a href="/#home"><img src={logo} alt="" /></a></li>
          <div 
            id="hamburger-menu"
            onClick={() => setIsVisible(!isVisible)}
          >
            <Menu fontSize="large" />
          </div>
          <li className={status}>
            <a href="/websites">websites</a>
          </li>
          <li className={status}>
            <a href="/apps">apps</a>
          </li>
          <li className={status}>
            <a href="/#games">games</a>
          </li>
          <li className={status}>
            <a href="/#bio">bio</a>
          </li>
          <li className={status}>
            <a href="/#contact">contact</a>
          </li>
          <li className={status}>
            <a href="./documents/jeremiah-shore-resume.pdf" target="_blank">resume</a></li>
          <li className={status}>
            <a href="/#extras">extras</a>
          </li>
        </ul>
      </nav>
    </header>
  );
}
