import { Button } from '@mui/material';
import React from 'react';

export const ListOfLinks = ({ items, color }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      { items.map(d => (
        <a
          href={d.path}
          target={'_blank'}
          style={{ marginRight: 20 }}
          rel="noreferrer"
        >
          <Button
            startIcon={d.icon}
            sx={{ color }}
            size="large"
          >
            {d.text}
          </Button>
        </a>
      ))}
    </div>
  );
};
