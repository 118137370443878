import React from 'react';
import { ContentSection } from '../content-section';
import './project-section.scss';
import {ProductFeatureDetails} from '../../product-feature/ProductFeatureDetails';

export const ProjectSection = (props) => {
  const { timeline, github, download, links, hosting, tech, copyWriting, asEmployee } = props;

  return(
    <ContentSection id={props.id}
      className={props.className + ' project-section'}
      title={props.title}
      subheading={props.description}
      featuredImage={props.featuredImage}
    >
      <ProductFeatureDetails
        timeline={timeline}
        github={github}
        download={download}
        links={links}
        hosting={hosting}
        tech={tech}
        copyWriting={copyWriting}
        asEmployee={asEmployee}
      />
      {props.children}
    </ContentSection>
  );
}