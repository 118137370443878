import React from 'react';
import { Box } from '@mui/material';

export const FeaturedImage = ({ url }) => {
  return (
    <Box
      sx={{
        margin: {
          xs: 'auto',
          sm: '0',
        }
      }}
      className={"featured-image-wrapper"}
    >
      <Box sx={{
        width: 320,
        height: 180,
        backgroundImage: `url(${url})`,
        backgroundSize: 'cover',
      }}/>
    </Box>
  );
};
