import React from 'react';
import './card.scss';
import { publicResource } from '../../utils/utils'

export function Card(props) {
  return (
    <a
      id={props.id}
      className="card"
      href={props.url ? props.url : '#'}
      style={{
        backgroundImage: `url(${props.image})`
      }}
      onClick={props.onClick}
    >
      <p>{props.label}</p>
    </a>
  );
}