import React from 'react';
import {GitHub, Link, PictureAsPdf, QueueMusic} from '@mui/icons-material';
import { ContentSection } from '../content-section/content-section';
import { ListOfLinks } from '../list-of-links/ListOfLinks';

const EXTRAS_ITEMS = [
  {
    text: 'resume',
    icon: <PictureAsPdf/>,
    path: './documents/jeremiah-shore-resume.pdf',
  },
  {
    text: 'work history',
    icon: <PictureAsPdf/>,
    path: './documents/jeremiah-shore-work-history.pdf',
  },
  {
    text: 'certifications',
    icon: <PictureAsPdf/>,
    path: './documents/jeremiah-shore-certifications.pdf',
  },
  {
    text: 'linkedin',
    icon: <Link/>,
    path: 'https://www.linkedin.com/in/jeremiah-shore-26428b73/',
  },
  {
    text: 'soundcloud',
    icon: <QueueMusic/>,
    path: 'http://soundcloud.com/user-620867192',
  },
  {
    text: 'jeremiah-shore',
    icon: <GitHub/>,
    path: 'https://github.com/jeremiah-shore',
  },
]

export const ExtrasSection = () => {
  return (
    <ContentSection
      id="extras"
      className="theme-gradient-blue"
      title="Extras"
    >
      <ListOfLinks items={EXTRAS_ITEMS} color={'common.white'} />
    </ContentSection>
  );
};
