import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { HomePage } from './pages/homepage/homepage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppsPage } from './pages/apps';
import { WesbitesPage } from './pages/websites';
import { createTheme, ThemeProvider } from '@mui/material';
import { WEBSITES } from './components/product-feature/ProductData';

const theme = createTheme({
  palette: {
    limegreen: '#a5d060',
  },
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomePage/>} />
        <Route path="/downloads" element={<AppsPage/>} />
        <Route path="/apps" element={<AppsPage/>} />
        <Route path="/websites" element={<WesbitesPage items={WEBSITES}/>} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
